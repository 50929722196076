import { send } from 'process';
import React from 'react';
import './FullLayout.css';

function PublicLayout(props: any) {

  return (
    <div className="full-layout">
      {props.children}
    </div>
  );
}

export default PublicLayout