// import mockOffices from './../mock/offices.json';

const initialState = {
  employee: null,
};

export default function employee(state = initialState, action) {
  switch (action.type) {
    case 'SET_EMPLOYEE':
      return {
        ...state,
        employee: action.employee
      };
    case 'GET_EMPLOYEE':
      return {
        ...state,
        employee: action.employee
      };
    default:
      return state;
  }
}
