import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button, Notification, NavigationBar, NavigationLink, Modal, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
import { useSelector } from 'react-redux';
import ModalCancelSession from './modalCancelSession/ModalCancelSession';

// import items from '../../../../store/items/ItemsReducers';
// import session_schedule_data from '../../../../store/mock/session_schedule.json';

function MySessionOverview() {
  const [recordsEmployee, setRecordsEmployee] = useState();
  const { employee } = useSelector((state: any) => state.default.employee);
  const [componentModal, setComponentModal] = useState<any|undefined>(undefined);
  
  const [notification, setNotification] = useState({status: false, className: '', message: ''});
  //const session = JS.getSession();
  
  useEffect(() => {
    if(employee && !recordsEmployee) {
      getRecordsEmployee();
    }
  });

  const getStatus = (status: string) => {
    switch (status) {
      case 'attended':
        return 'Atendido';
        break;
      case 'absent':
        return 'Ausente';
        break;
      case 'not_attended':
        return 'No atendido';
        break;
      default:
        break;
    }
  }

  // Get branch related to the meet
  async function getRecordsEmployee() {
    const { employee_id } = employee;

    const data = {
      "employee_id": employee_id,
      "record_status": "in_process"
    };
    const endpoint = `/record/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('>>>> getRecordsEmployee: result: ', result);
      setRecordsEmployee(result);
    } catch (err) {
      console.log(err)
    }
  };

  function showModalCancelSession(sessionItem:any) {
    setComponentModal(<ModalCancelSession close={()=>closeModal()} accept={(modalData:any)=>acceptModal(modalData)} recordItem={sessionItem} />);
  }

  function acceptModal(modalData:any) {
    if (modalData.delete_accept) {
      removeSession(modalData);
      console.log('accept modalData: ', modalData);
    }
    setComponentModal(null);
  }

  function closeModal() {
    setComponentModal(null);
  }

  async function removeSession(modalData:any) {
    // event.preventDefault();

    const { record_id } = modalData;

    const data = {
      "record_id": record_id
    }

    const endpoint = `/record/delete`;
    // console.log('data: ', data);

    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      if(result) {
        setNotification({
          status: true,
          className: 'success',
          message: 'Tu reserva se ha anulado correctamente.'
        });
        getRecordsEmployee();
      }
    } catch (err) {
      console.log(err)
    }
  };

  const blockRecordsEmployee = (recordsEmployee: any) => {
    const list = recordsEmployee.map((item: any, index: string)=> {
      return (
        <Tr key={index}>
          <Td>{JS.professionTypeToName(item.meet_type)}</Td>
          <Td>{JS.meetFormat.interval(item.record_time_start, item.record_time_end)}</Td>
          <Td>€0,00</Td>
          <Td>{item.branch_name}</Td>
          <Td>
            <div className='position-right btn-group'>
              <Button label='Anular reserva' action={()=> showModalCancelSession(item)}/>
              {/*<Link to={`/dashboard/assessment/session/record/${item.record_id}`}>
                
      </Link>*/}
            </div>
          </Td>
        </Tr>
      )
    })
    return list;
  }

  if(!employee) {
    return (<div>
      <Section>
        <Row>
          <Col>
            Loading...
          </Col>
        </Row>
      </Section>
    </div>)
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col><H2>Mis reservas</H2>
          </Col>
          <Col className='align-right'>
            <Link to={'/dashboard/booksession'}><Button className='primary' label='Reservar nueva cita' action={()=>null} /></Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Notification className='alert'>Importante: En caso de anular tu cita deberás notificar 24hs antes, de lo contrario se cobrará una penalización.</Notification>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Servicio</Th><Th>Fecha de cita</Th><Th>Pago</Th><Th>Lugar</Th><Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                { (recordsEmployee) ? (
                  blockRecordsEmployee(recordsEmployee)
                ): null}
                </Tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Section>
      <Modal close={()=>closeModal()} component={componentModal}/>
    </div>
  );
}

export default MySessionOverview;