export const test = (name: String) => {
  return 0;
}

export const meetTypeToName = (meet_type: string) => {
  switch (meet_type) {
    case 'fisioterapia':
      return 'Fisioterapia'
    case 'nutricion' :
      return 'Nutrición'
    default:
      return 'Indefinido'
  }
}