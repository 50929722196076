/* Pages */
// import ErrorPage from '../containers/errorPage/ErrorPage';
import IRoute from '../models/route';

// Session
import MySessionOverview from '../pages/private/mySession/overview/Overview';
import MySessionHistory from '../pages/private/mySession/history/History';
import BookSession from '../pages/private/mySession/bookSession/BookSession';

import Profile from '../pages/private/profile/Profile';
import Payment from '../pages/private/payment/Payment';
import BookSessionPublic from '../pages/public/bookSessionPublic/BookSessionPublic';

import CreateEmployee from '../pages/private/employee/create/CreateEmployee';

// Private
// import Search from '../pages/private/search/Search';

/* Layouts */
// import PublicLayout from '../layouts/publicLayout/PublicLayout';
import FullLayout from '../layouts/fullLayout/FullLayout';
import DashboardLayout from '../layouts/dashboardLayout/DashboardLayout';

// import PrivateLayout from '../layouts/privateLayout/PrivateLayout';

export const routes: IRoute[] = [
  {
    path: '/dashboard/payment',
    name: 'Payment Page',
    component: Payment,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/booksession',
    name: 'Book Session Page',
    component: BookSession,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/profile',
    name: 'Profile Overview Page',
    component: Profile,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/history',
    name: 'My Session History Overview Page',
    component: MySessionHistory,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard',
    name: 'My Session Overview Page',
    component: MySessionOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/booking/:branch_id',
    name: 'Book Session Public Overview Page',
    component: BookSessionPublic,
    layout: FullLayout,
    exact: true
  },
  {
    path: '/employee/create',
    name: 'CreateEmployee Overview Page',
    component: CreateEmployee,
    layout: FullLayout,
    exact: true
  },
  {
    path: '/',
    name: 'My Session Overview Page',
    component: MySessionOverview,
    layout: DashboardLayout,
    exact: true
  }
];