import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Section, Row, Col, H2, H4, H5, Box, Input, Notification, InputRadio, Button, NavigationBar, NavigationLink, Footer, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../store/api/api';
import { meetTypeToName } from '../../../lib/Lib';
// import items from '../../../../store/items/ItemsReducers';
import './BookSessionPublic.css';

function BookSessionPublic() {
  let history = useHistory();
  const { branch_id } = useParams<any>();
  const [meets, setMeets] = useState();
  const [branch, setBranch] = useState({
    branch_name:''
  });
  const [sessionSelection, setSessionSelection] = useState([]);
  const [notification, setNotification] = useState({status: false, className: '', message: ''});
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [bookingSent, setBookingSent] = useState(false);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    repeat_email: '',
    password: '',
    repeat_password: ''
  });

  useEffect(() => {
    if(!meets) {
      getBranch();
      getMeets();
    }
    console.log('sessionSelection: ', sessionSelection);
  });

  const meetFormat = (time_start: any, time_end: any) => {
      if(!time_start&&!time_end) {
        return 'Undefined';
      }
  
      const date_converter = new Date(time_start * 1000);
      const dateYear = date_converter.getUTCFullYear();
      const dateMonth = date_converter.getMonth()>9 ? date_converter.getMonth() : "0"+date_converter.getMonth();
      const dateDay = date_converter.getDay()>9 ? date_converter.getDay() : "0"+ date_converter.getDay();
  
      const date_converter_end = new Date(time_end * 1000);
      const date = `${dateDay}/${dateMonth}/${dateYear}`;
      const hour_start = date_converter.getHours()>9 ? date_converter.getHours() : "0"+date_converter.getHours();
      const minutes_start = date_converter.getMinutes()>9 ? date_converter.getMinutes() : "0"+date_converter.getMinutes();
      const result_time_start = hour_start+':'+minutes_start;
  
      const hour_end = date_converter_end.getHours()>9 ? date_converter_end.getHours() : "0"+date_converter_end.getHours();
      const minutes_end = date_converter_end.getMinutes()>9 ? date_converter_end.getMinutes() : "0"+date_converter_end.getMinutes();
      const result_time_end = hour_end+':'+minutes_end;
  
      return `${date} ${result_time_start}hs a ${result_time_end}hs`;    

  }

  // Get branch related to the meet
  async function getMeets() {
    const data = {
      "branch_id" : branch_id
    };
    const endpoint = `/meet/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('result: ',result);
      setMeets(result);
    } catch (err) {
      console.log(err)
    }
  };

  // Get branch related to the meet
  async function getBranch() {
    const data = {
      "branch_id" : branch_id
    };
    const endpoint = `/branch`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('getBranch: branch: ',result);
      setBranch(result);
    } catch (err) {
      console.log(err)
    }
  };

  const isSessionChecked =  (meet_id: any, sessionIndex: string) => {    
    let exist = false;
    if (sessionSelection!==undefined) {
      sessionSelection.forEach((item:any) => {
        if(item.meet_id===meet_id && item.sessionIndex === sessionIndex) {
          exist = true;
        }
      });      
    }
    return exist;
  }

  const handleSessionCheck = (meet_id: string, sessionIndex: string, meet: any) => {
    let arraySession = JSON.parse(JSON.stringify(sessionSelection));
    if(isSessionChecked(meet_id, sessionIndex)) {
      // remove item
      let arraySessionClear:any[]=[];
      arraySession.forEach((item:any)=> {
        if(item.meet_id===meet_id && item.sessionIndex === sessionIndex) {
          console.log(item);
        } else {
          arraySessionClear.push(item);
        }
      })
      arraySession = arraySessionClear;
    } else {
      // add item
      const data = {
        meet_id,
        sessionIndex,
        meet
      }
      arraySession.push(data);    
    }
    setSessionSelection(arraySession);
  }

  // Get branch related to the meet
  async function createRecord(item:any, employee_id: string) {
    const {meet_id, sessionIndex, meet} = item;
    const meet_sessions_array = JSON.parse(meet.meet_sessions);
    const employee_fullname = `${formData.firstname} ${formData.lastname}`;
    //const employee_id =JS.getCookie({cname:'account_id'});

    const data = {
      "meet_id": meet_id,
      "company_id": "",
      "employee_id": employee_id,
      "employee_fullname": employee_fullname,
      "record_status": "in_process",
      "record_time_start": meet_sessions_array[sessionIndex].record_start.toString(),
      "record_time_end": meet_sessions_array[sessionIndex].record_end.toString(),
    }
    const endpoint = `/record/create`;

    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('>>>> createRecord /record/create result: ', result);
    } catch (err) {
      console.log(err)
    }
  };

  const submitBookSession = (employee_id: string) => {
    sessionSelection.forEach(async (item) => {
      await createRecord(item, employee_id);
    });
    setBookingSent(true);
  }

  const blockMeets = (meets: any) => {
    console.log('meets: ', meets);
    const list = meets.map((meetItem:any, index:number)=> {
      const {meet_sessions} = meetItem;
      const meet_sessions_array = JSON.parse(meet_sessions);

      return (
        <div key={`${meetItem.meet_id}${index}`}>
          <Row>
            <Col>
              <H5>{meetTypeToName(meetItem.meet_type)}</H5>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='wrapper-checkbox-items'>
                {
                  meet_sessions_array.map((sessionItem:any, sessionIndex: string)=>{
                    if(sessionItem.record_id==='0') {
                      const checked=isSessionChecked(meetItem.meet_id, sessionIndex);
                      return (
                        <Row key={`${meetItem.meet_id}${sessionIndex}`}>
                          <Col>
                            <div className='check-wrapper'>
                              <input className='check-item' type='checkbox' checked={checked} onClick={()=>handleSessionCheck(meetItem.meet_id, sessionIndex, meetItem)}/>
                              <div>{JS.meetFormat.interval(parseInt(sessionItem.record_start),parseInt(sessionItem.record_end))}</div>
                            </div>
                          </Col>
                          <Col className='align-right w-20'>
                            €0,00
                          </Col>
                          <Col className='w-20'></Col>
                        </Row>
                      )
                    }
                    else {
                      return (
                        <Row key={`${meetItem.meet_id}${sessionIndex}`}>
                          <Col><div className='text-gray'>{`${JS.meetFormat.interval(sessionItem.session_start, sessionItem.session_start)}`}</div></Col><Col></Col>
                        </Row>
                      )
                    }
                  })
                }
              </div>
            </Col>
          </Row>
        </div>
      )
    });
    return list;
  }

  const handleInputChange = (event:any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  async function createEmployee() {
    const account_id = JS.getCookie({cname:'account_id'});
    const employee_fullname = `${formData.firstname} ${formData.lastname}`;
    const data = {
      "branch_id": branch_id,
      "company_id": "",
      "employee_fullname": employee_fullname,
      "employee_location_address": "",
      "employee_location_city": "",
      "employee_location_state": "",
      "employee_location_country": "",
      "account_id": account_id
    };
    const endpoint = `/employee/create`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result:any = res.data;
      console.log('createEmployee: res.data: ', res.data);
      if (result) {
        const employee_id = result && result.data ? result.data.employee_id : 0;
        submitBookSession(employee_id);
      }
      // 
    } catch (err) {
      console.log(err)
    }
    
    /*const data = {
      "account_email": formData.email.toLowerCase(),
      "account_password": formData.password,
      "account_firstname": formData.firstname,
      "account_lastname": formData.lastname,
      "account_role": "employee"
    };

    const endpoint = `/account/create`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      console.log(res.data);

      if (result === "true") {
    */
  }

  async function createAccount(event:any) {
    event.preventDefault();
    if((formData.firstname==='') ||
      (formData.lastname==='') ||
      (formData.email==='') ||
      (formData.repeat_email==='') || ((formData.email!=='') && 
      (formData.repeat_email!=='') && (formData.repeat_email!==formData.email)) || 
      (formData.password==='') || (formData.repeat_password==='')) {
      setNotification({
        status: true,
        className: 'danger',
        message: 'Debe completar todos los campos.'
      });
      if (formData.repeat_email!==formData.email) {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Los campos "Email" y "Repetir Email" deben ser iguales.'
        });
      }
      setLoading(false);
    } else {
      setNotification({
        status: false,
        className: '',
        message: ''
      });
      if(!checked) {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Debe aceptar los términos y condiciones.'
        });
        setLoading(false);
      } else {
        const data = {
          "account_email": formData.email.toLowerCase(),
          "account_password": formData.password,
          "account_firstname": formData.firstname,
          "account_lastname": formData.lastname,
          "account_role": "employee"
        };
  
        const endpoint = `/account/create`;
        try {
          const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
          const { result } = res.data;

          if (result === "true") {
              // setAccountCreated(true);
            makeBooking();
          } else {
            setNotification({
              status: true,
              className: 'danger',
              message: 'El email ya existe en nuestra base de datos. Accede a https://improovia.com e inicia sesión con tu cuenta.'
            });
            /*if(res.data.error) {
              setNotification({
                status: true,
                className: 'danger',
                message: 'El email ya existe en nuestra base de datos.'
              });
            } else {
              setNotification({
                status: true,
                className: 'danger',
                message: 'Error al ingresar los datos.'
              });
            }*/
            setLoading(false);
          }
        } catch (err) {
          console.log(err)
          setNotification({
            status: true,
            className: 'danger',
            message: 'Error al ingresar los datos.'
          });
          setLoading(false);
        }
      }
    }
  }

  async function makeBooking() {
    const data = {
      "account_email": formData.email.toLowerCase(),
      "account_password": formData.password
    };

    const endpoint = `/account/login`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const user:any = res.data;
      if(user && user.account_id) {
        JS.setCookie({
          cname:'account_id',
          cvalue: user.account_id,
          exdays: 10
        });
        createEmployee()        
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleCheckChange = () => {
    setChecked(!checked);
  };

  if(!branch) {
    return (
      <div>
        <Section className='medium'>
          Loading...
        </Section>
      </div>
    )
  }

  if(bookingSent && branch) {
    return (
      <div>
        <Section className='medium'>
          <Row>
            <Col className='text-align-center'>
              <H2>{branch.branch_name}</H2>
              <H4>Reservar cita</H4>
            </Col>
          </Row>
          <Box className='wrapper-book'>
            <Row>
              <Col className='content'>
                <Row>
                  <Col>
                    <Notification className='success'>La reserva se ha realizado correctamente.</Notification>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <a href='https://account.improovia.com'>
                      <Button action={()=>null} label='Iniciar sesión'></Button>
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
        </Section>
      </div>
    )
  }

  if(branch) {
    return ( 
      <div>
        <Section className='medium'>
          <Row>
            <Col className='text-align-center'>
              <H2>{branch.branch_name}</H2>
              <H4>Reservar cita</H4>
            </Col>
          </Row>
          <Box className='wrapper-book'>
            <Row className='mb-vertical'>
              <Col className='content'>
                  {(meets) ? blockMeets(meets) : null}
              </Col>
              <Col className='form'>
                <Row>
                  <Col><H5>Datos personales</H5></Col>
                </Row>
                  <form onSubmit={ e =>createAccount(e)}>
                    {(notification.status) ? (
                      <Row>
                        <Col>
                          <Notification className={notification.className}>{notification.message}</Notification>
                        </Col>
                      </Row>) : null
                    }
                    <Row>
                      <Col>
                        <Input type='text' name='firstname' value={formData.firstname} placeholder='Nombre' onChange={handleInputChange}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Input type='text' name='lastname' value={formData.lastname} placeholder='Apellido' onChange={handleInputChange}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Input type='email' name='email' value={formData.email} placeholder='Email' onChange={handleInputChange}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Input type='email' name='repeat_email' value={formData.repeat_email} placeholder={'Repetir email'} onChange={handleInputChange}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Input type='password' name='password' value={formData.password} placeholder='Password' onChange={handleInputChange}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Input type='password' name='repeat_password' value={formData.repeat_password} placeholder='Repetir password' onChange={handleInputChange}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <input
                          type="checkbox"
                          className='space'
                          checked={checked}
                          onChange={handleCheckChange}
                        /> Acepta los <a href="https://account.improovia.com/terminos-condiciones.pdf" target="_blank" rel="noreferrer">Términos y condiciones</a>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <button className='btn primary'>Registrarme & resevar cita</button>
                        {/*<Button label={t('sign_up.sign_up')} className='primary' loading={loading} action={null} />*/}
                      </Col>
                    </Row>
                  </form>
              </Col>
            </Row>
          </Box>
        </Section>
        <Footer />
      </div>
    );
  }

}

export default BookSessionPublic;