const setEmployee = function (employee) {
  return {
    type: 'SET_EMPLOYEE',
    employee
  };
};

const getEmployee = function (employee) {
  return {
    type: 'GET_EMPLOYEE',
    employee: {
      employee_id: '111111'
    }
  };
};


export { setEmployee, getEmployee };
