import React, { useEffect } from 'react';
import SideMenu from '../../components/sideMenu/SideMenu';
import './DashboardLayout.css';
import { Container, HeaderDashboard, JS } from 'im-ui-pack';
import sessionLinks from '../../store/mock/sessionLinks.json';
import { request, ContentTypes } from '../../store/api/api';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setEmployee } from '../../store/employee/EmployeeActions';

// employee_id: UnSzqMU6oX8KnevzSJxJig

/*
const user = {
  account_id: 'oScNqa6efSw5MxZADmTZ33',
  account_email: 'teste37@gmail.com',
  account_firstname: 'Test e37',
  account_lastname: 'Test e37',
  account_role: 'employee'
};

JS.setSession({ user });
const session_token = JS.getSession();
console.log('session_token: ',session_token);
*/

function DashboardLayout(props: any) {
  const history = useHistory();
  const session = JS.getSession();
  const { employee } = useSelector((state: any) => state.default.employee);
  const dispatch = useDispatch();

  useEffect(()=> {
    if(employee===null) {
      getEmployee()
    }
    console.log('state: employe:', employee)
  })

  async function getEmployee() {
    const { account_id } = session.account;
    const data = {
      account_id
    };
    const endpoint = `/employee/account`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      if (result && result!=='error') {
        const employee_data:any = result;
        console.log('······· getEmployee: employee_data:',employee_data);
        if(employee_data.employee_location_country==='') {
          dispatch(setEmployee(employee_data));
          history.push('/employee/create')
        } else {
          dispatch(setEmployee(employee_data));
        }
      }/* else {
        
      }*/
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div className="dashboard-layout">
      <HeaderDashboard sessionLinks={sessionLinks}></HeaderDashboard>
      <SideMenu />
      <div className='main-wrapper'>
        <Container>
          {props.children}
        </Container>
      </div>
    </div>
  );
}

export default DashboardLayout