import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Section, Row, Col, H2, H3, H5, Box, InputRadio, Button, NavigationBar, NavigationLink, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
import { meetTypeToName } from '../../../../lib/Lib';
import { useSelector } from 'react-redux';
// import items from '../../../../store/items/ItemsReducers';
import './BookSession.css';

function BookSession() {
  let history = useHistory();
  const [meets, setMeets] = useState();
  const [sessionSelection, setSessionSelection] = useState([]);
  const { employee } = useSelector((state: any) => state.default.employee);
  const branch_id = employee.branch_id;
  console.log('BookSession: employee: ', employee);
  useEffect(() => {
    if(!meets) {
      getMeets();
    }
  });

  // Get branch related to the meet
  async function getMeets() {
    const data = {
      "branch_id" : branch_id
    };
    const endpoint = `/meet/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('result: ',result);
      setMeets(result);
    } catch (err) {
      console.log(err)
    }
  };

  const isSessionChecked =  (meet_id: any, sessionIndex: string) => {    
    let exist = false;
    if (sessionSelection!==undefined) {
      sessionSelection.forEach((item:any) => {
        if(item.meet_id===meet_id && item.sessionIndex === sessionIndex) {
          exist = true;
        }
      });      
    }
    return exist;
  }

  const handleSessionCheck = (meet_id: string, sessionIndex: string, meet: any) => {
    let arraySession = JSON.parse(JSON.stringify(sessionSelection));
    if(isSessionChecked(meet_id, sessionIndex)) {
      // remove item
      let arraySessionClear:any[]=[];
      arraySession.forEach((item:any)=> {
        if(item.meet_id===meet_id && item.sessionIndex === sessionIndex) {
          console.log(item);
        } else {
          arraySessionClear.push(item);
        }
      })
      arraySession = arraySessionClear;
    } else {
      // add item
      const data = {
        meet_id,
        sessionIndex,
        meet
      }
      arraySession.push(data);    
    }
    setSessionSelection(arraySession);
  }

  // Get branch related to the meet
  async function createRecord(item:any) {
    const {meet_id, sessionIndex, meet} = item;
    const { employee_id, employee_fullname, company_id } = employee;
    const meet_sessions_array = JSON.parse(meet.meet_sessions);
    console.log('item: ', item);
    const data = {
      "meet_id": meet_id,
      "company_id": company_id,
      "employee_id": employee_id,
      "employee_fullname": employee_fullname,
      "record_status": "in_process",
      "record_time_start": meet_sessions_array[sessionIndex].record_start.toString(),
      "record_time_end": meet_sessions_array[sessionIndex].record_end.toString(),
    }

    const endpoint = `/record/create`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
    } catch (err) {
      console.log(err)
    }
  };

  const submitBookSession = () => {
    sessionSelection.forEach(async (item) => {
      await createRecord(item);
    });
    window.location.href='/dashboard'
  }

  const blockMeets = (meets: any) => {
    console.log('meets: ', meets);
    const list = meets.map((meetItem:any, index:number)=> {
      const {meet_sessions} = meetItem;
      const meet_sessions_array = JSON.parse(meet_sessions);

      return (
        <div key={`${meetItem.meet_id}${index}`}>
          <Row>
            <Col>
              <H5>{meetTypeToName(meetItem.meet_type)}</H5>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='wrapper-checkbox-items'>
                {
                  meet_sessions_array.map((sessionItem:any, sessionIndex: string)=>{
                    if(sessionItem.record_id==='0') {
                      const checked=isSessionChecked(meetItem.meet_id, sessionIndex);
                      return (
                        <Row key={`${meetItem.meet_id}${sessionIndex}`}>
                          <Col>
                            <div className='check-wrapper'>
                              <input className='check-item' type='checkbox' checked={checked} onClick={()=>handleSessionCheck(meetItem.meet_id, sessionIndex, meetItem)}/>
                              <div>{JS.meetFormat.interval(parseInt(sessionItem.record_start),parseInt(sessionItem.record_end))}</div>
                            </div>
                          </Col>
                          <Col className='w-20'>
                            €0,00
                          </Col>
                        </Row>
                      )
                    }
                    else {
                      return (
                        <Row key={`${meetItem.meet_id}${sessionIndex}`}>
                          <Col><div className='text-gray'>{`${JS.meetFormat.interval(sessionItem.session_start, sessionItem.session_start)}`}</div></Col><Col></Col>
                        </Row>
                      )
                    }
                  })
                }
              </div>
            </Col>
          </Row>
        </div>
      )
    });
    return list;
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Reservar cita</H2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Box>
              {(meets) ? blockMeets(meets) : null}
            </Box>
          </Col>
        </Row>

        <Row>
          <Col className='align-right'>
            <div className='btn-group'>
              <Button className='' label='Cancelar' action={()=>null} />
              <Button className='primary' label='Reservar cita' action={()=>submitBookSession()}/>
            </div>
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default BookSession;