import { combineReducers } from 'redux';

// import booking from './booking/BookingReducers';
import items from './items/ItemsReducers';
import employee from './employee/EmployeeReducers';

export default combineReducers({
  items,
  employee,
});
