import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Section, Row, Col, H2, H3, H4, H5, Box, Input, Notification, Button, Footer, JS} from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
// import assessment_data from './../../../../store/mock/assessment.json';
import './CreateEmployee.css';
import { useHistory } from 'react-router-dom';
import es_states from '../../../../store/mock/es_states.json';
import { useSelector } from 'react-redux';

function CreateEmployee() {
  const history = useHistory();
  const session = JS.getSession();
  const { account_id, account_firstname, account_lastname, account_email } = session.account;
  const [notification, setNotification] = useState({status: false, className: '', message: ''});
  const { employee } = useSelector((state: any) => state.default.employee);
  const employee_fullname = `${account_firstname} ${account_lastname}`
  const [formData, setFormData] = useState({
    account_id,
    employee_fullname: employee_fullname,
    employee_location_address: '',
    employee_location_city: '',
    employee_location_state: '',
    employee_location_country: 'ES'
  });

  useEffect(() => {
    console.log('employee: ', employee);
  });

  async function handleSubmit(event: any) {
    event.preventDefault();
    if((formData.employee_fullname==='') ||
      (formData.employee_location_address==='') ||
      (formData.employee_location_city==='') ||
      (formData.employee_location_state==='')) {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Debe completar todos los campos.'
        });
    }
    else {
      const session = JS.getSession();
      // const date_birthday = new Date(formData.professional_birthday);
      // const professional_birthday = JS.dateFormat.dateToTimestamp(date_birthday.getTime()/1000);
      console.log('>> employee: ', employee);
      if(employee) {
        const data = {
          employee_id: employee.employee_id,
          account_id,
          employee_fullname: formData.employee_fullname,
          company_id: employee.company_id,
          employee_location_address: formData.employee_location_address,
          employee_location_city: formData.employee_location_city,
          employee_location_state: formData.employee_location_state,
          employee_location_country: 'ES',
        };
        const endpoint = `/employee/update`;
        try {
          const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
          const result = res.data;
          history.push('/dashboard');
        } catch (err) {
          console.log(err)
        }        
      }
    }
  };

  const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  return ( 
    <div>
      <Section className='medium'>
        <Row>
          <Col className='text-align-center'>
            <H3>Mis datos</H3>
          </Col>
        </Row>
        <Box>
          <form onSubmit={ e =>handleSubmit(e)}>
            {(notification.status) ? (
              <Row>
                <Col>
                  <Notification className={notification.className}>{notification.message}</Notification>
                </Col>
              </Row>) : null
            }
            <Row>
              <Col>
                <div>Nombre completo</div>
                <Input type='text' name='employee_fullname' value={formData.employee_fullname} placeholder={'Nombre completo'} onChange={handleInputChange}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>Email</div>
                <Input type='text' name='employee_email' value={account_email} placeholder={'Email'} onChange={handleInputChange}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>Dirección</div>
                <Input type='text' name='employee_location_address' value={formData.employee_location_address} placeholder={'Dirección'} onChange={handleInputChange}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>Ciudad</div>
                <Input type='text' name='employee_location_city' value={formData.employee_location_city} placeholder={'Ciudad'} onChange={handleInputChange}/>
              </Col>
            </Row>
            <Row className='mb-vertical'>
              <Col>
                <div>Provincia</div>
                <select className='inp' name='employee_location_state' value={formData.employee_location_state} onChange={handleInputChange}>
                  {
                    es_states.map((item, index)=>{
                      return (<option key={index} value={item.slug}>{item.state}</option>)
                    })
                  }
                </select>
              </Col>
              <Col>
                <div>País</div>
                <select className='inp' name='employee_location_country' value={formData.employee_location_country} onChange={handleInputChange}>
                  <option value='ES'>España</option>
                </select>
              </Col>
            </Row>
            {/*
            <Row>
              <Col>Documentos</Col>
              <Col>
                <Button className='btn-secondary' action={()=>null} label='Subir'></Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <H5>Observaciones</H5>
              </Col>
            </Row>
            <Row>
              <Col>
                <textarea placeholder='Comentario'></textarea>
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
            */}
            <Row>
              <Col className='align-right'>
                <button className='btn primary'>Guardar</button>
              {/*<Button className='primary' label='Agregar empresa' action={()=>handleSubmit()} />*/}
              </Col>
            </Row>
          </form>

        </Box>
      </Section>
      <Footer />
    </div>
  );
}

export default CreateEmployee;