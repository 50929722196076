import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, NavigationBar, NavigationLink, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
import { useSelector } from 'react-redux';

// import items from '../../../../store/items/ItemsReducers';
// import session_schedule_history_data from './../../../../store/mock/session_schedule_history.json';

function MySessionHistory() {
  const [recordsEmployee, setRecordsEmployee] = useState();
  const { employee } = useSelector((state: any) => state.default.employee);

  useEffect(() => {
    if(!recordsEmployee) {
      getRecordsEmployee();
    }
  });

  const getStatus = (status: string) => {
    switch (status) {
      case 'attended':
        return 'Atendido';
        break;
      case 'absent':
        return 'Ausente';
        break;
      case 'not_attended':
        return 'No atendido';
        break;
      default:
        break;
    }
  }

  // Get branch related to the meet
  async function getRecordsEmployee() {
    const { employee_id } = employee;

    const data = {
      "employee_id": employee_id,
      "record_status": "attended"
    };
    const endpoint = `/record/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('result: ', result);
      setRecordsEmployee(result);
    } catch (err) {
      console.log(err)
    }
  };
/*
  const getMeetTime = (meet:any) => {
    return meet.map((meetItem)=>{
      return (
        <div>
          <div>{meetItem.time_begin}</div>
          <div>{meetItem.time_end}</div>
        </div>
      );
    });
  }

  const getMeetEmployeeSubscribers = (meet: any) => {
    let total_employee_subscribers = 0;
    meet.forEach(meetItem => {
      total_employee_subscribers += parseFloat(meetItem.employee_subscribers);
    })
    return total_employee_subscribers;
  }

  const getAssessmentSubscription = (item: any) => {
    return (
      <div>
        <div>{item.employee_subscription}/{item.employee_subscription_total}</div>
        <div>{(item.employee_subscription * 100)/item.employee_subscription_total }% Completado</div>
      </div>
    );
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{item.time_begin}</div>
        <div>{item.time_end}</div>
      </div>
    );
  }*/

  const blockRecordsEmployee = (recordsEmployee: any) => {
    const list = recordsEmployee.map((item: any, index: string)=> {
      return (
        <Tr key={index}>
          <Td>{JS.professionTypeToName(item.meet_type)}</Td>
          <Td>{JS.meetFormat.interval(item.record_time_start, item.record_time_end)}</Td>
          <Td>€0,00</Td>
          <Td>{item.branch_name}</Td>
          <Td>
            {getStatus(item.record_status)}
          </Td>
        </Tr>
      )
    })
    return list;
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Historico</H2>
          </Col>
        </Row>
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Servicio</Th><Th>Fecha de cita</Th><Th>Pago</Th><Th>Lugar</Th>
              </Tr>
              </Thead>
              <Tbody>
              {(recordsEmployee) ? (
                  blockRecordsEmployee(recordsEmployee)
                ) : null
              }
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default MySessionHistory;