import React, { useEffect, useState } from 'react';
import { Row, Col, Notification, Input, Button } from 'im-ui-pack';

const ModalCancelSession = (props: any) => {
  const { close, accept, recordItem } = props;
  const { record_id, branch_name } = recordItem;
  const [formData, setFormData] = useState({
    delete_accept: true,
    record_id: record_id
  });

  useEffect(()=>{
    console.log('ModalCancelSession: recordItem: ', recordItem);
  })

  const acceptModal = () => {
    accept(formData);
  }

  const closeModal = () => {
    close();
  }

 /* const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }
*/
  return (
    <div>
      <div className='modal-header'>Anular reserva</div>
      <div className='modal-content'>
        <Row>
          <Col>
            {`¿Desea anular la reserva en "${branch_name}"?`}
          </Col>
        </Row>
      </div>  
      <div className='modal-bottom'>
        <Button className='secondary-outline' action={()=>closeModal()} label='Cancelar'/>
        <Button className='primary' action={()=>acceptModal()} label='Anular reserva'/>
      </div>
    </div>
  )
}

export default ModalCancelSession;
