import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, NavigationBar, NavigationLink } from 'im-ui-pack';
// import { requestSwitcherAPI, ContentTypes } from '../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
// import session_schedule_history_data from './../../../store/mock/session_schedule_history.json';
import { useSelector } from 'react-redux';

function Profile() {
  const { employee } = useSelector((state: any) => state.default.employee);
  const [employeeProfile, setEmployeeProfile] = useState<any>();

  useEffect(() => {
    console.log('employee: ', employee);
    if(employee!==null) {
      const data = {
        account_email: employee.account_email,
        account_firstname: employee.account_firstname,
        account_lastname: employee.account_lastname,
        employee_location_address: employee.employee_location_address,
        employee_location_city: employee.employee_location_city,
        employee_location_state: employee.employee_location_state,
        employee_location_country: employee.employee_location_country
      };
      setEmployeeProfile(data);
    }
  });

  

  const getStatus = (status: string) => {
    switch (status) {
      case 'attended':
        return 'Atendido';
        break;
      case 'absent':
        return 'Ausente';
        break;
      case 'not_attended':
        return 'No atendido';
        break;
      default:
        break;
    }
  }

/*
  const getMeetTime = (meet:any) => {
    return meet.map((meetItem)=>{
      return (
        <div>
          <div>{meetItem.time_begin}</div>
          <div>{meetItem.time_end}</div>
        </div>
      );
    });
  }

  const getMeetEmployeeSubscribers = (meet: any) => {
    let total_employee_subscribers = 0;
    meet.forEach(meetItem => {
      total_employee_subscribers += parseFloat(meetItem.employee_subscribers);
    })
    return total_employee_subscribers;
  }

  const getAssessmentSubscription = (item: any) => {
    return (
      <div>
        <div>{item.employee_subscription}/{item.employee_subscription_total}</div>
        <div>{(item.employee_subscription * 100)/item.employee_subscription_total }% Completado</div>
      </div>
    );
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{item.time_begin}</div>
        <div>{item.time_end}</div>
      </div>
    );
  }*/

  if (!employeeProfile) {
    return ( 
      <div>
        <Section>
          <Row>
            <Col>
              <H2>Perfil</H2>
            </Col>
          </Row>
          <Row>
            <Col>
              Loading...
            </Col>
          </Row>
        </Section>
      </div>
    )
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Perfil</H2>
          </Col>
        </Row>
        <Row>
          <Col className='w-20'>
            Nombre
          </Col>
          <Col>
            {employeeProfile.account_firstname}
          </Col>
        </Row>
        <Row>
          <Col className='w-20'>
            Apellidos
          </Col>
          <Col>
            {employeeProfile.account_lastname}
          </Col>
        </Row>
        <Row>
          <Col className='w-20'>
            Email
          </Col>
          <Col>
            {employeeProfile.account_email}
          </Col>
        </Row>
        <Row>
          <Col className='w-20'>
            Dirección
          </Col>
          <Col>
            {employeeProfile.employee_location_address}
          </Col>
        </Row>
        <Row>
          <Col className='w-20'>
            Ciudad
          </Col>
          <Col>
            {employeeProfile.employee_location_city}
          </Col>
        </Row>
        <Row>
          <Col className='w-20'>
            Provincia
          </Col>
          <Col>
            {employeeProfile.employee_location_state}
          </Col>
        </Row>
        <Row>
          <Col className='w-20'>
            País
          </Col>
          <Col>
            {employeeProfile.employee_location_country}
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default Profile;