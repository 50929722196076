import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, H3, Button, Box, NavigationBar, NavigationLink } from 'im-ui-pack';
import { requestSwitcherAPI, ContentTypes } from '../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import session_schedule_history_data from './../../../store/mock/session_schedule_history.json';

function Payment() {
  useEffect(() => {});

  const getStatus = (status: string) => {
    switch (status) {
      case 'attended':
        return 'Atendido';
        break;
      case 'absent':
        return 'Ausente';
        break;
      case 'not_attended':
        return 'No atendido';
        break;
      default:
        break;
    }
  }

/*
  const getMeetTime = (meet:any) => {
    return meet.map((meetItem)=>{
      return (
        <div>
          <div>{meetItem.time_begin}</div>
          <div>{meetItem.time_end}</div>
        </div>
      );
    });
  }

  const getMeetEmployeeSubscribers = (meet: any) => {
    let total_employee_subscribers = 0;
    meet.forEach(meetItem => {
      total_employee_subscribers += parseFloat(meetItem.employee_subscribers);
    })
    return total_employee_subscribers;
  }

  const getAssessmentSubscription = (item: any) => {
    return (
      <div>
        <div>{item.employee_subscription}/{item.employee_subscription_total}</div>
        <div>{(item.employee_subscription * 100)/item.employee_subscription_total }% Completado</div>
      </div>
    );
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{item.time_begin}</div>
        <div>{item.time_end}</div>
      </div>
    );
  }*/

  return ( 
    <div className='container-center'>
      <Section>
        <Row>
          <Col>
          </Col>
        </Row>
        <Box> 
          <Row>
            <Col className='align-center'>
              <H2>Realizar pago</H2>
            </Col>
          </Row>
          <Row>
            <Col className='align-center'>
              <div>Sucursal: Mercadona Gracia</div>
              <div>Locación: Carrer d'Astúries, 68, Gracia - Barcelona</div>
              <div>Fecha: 15/09/2022 - 14hs a 19hs</div>
            </Col>
          </Row>
          <Row>
            <Col className='align-center'>
              <div>Detalle: Fisio</div>
            </Col>
          </Row>
          <Row>
            <Col className='align-center'>
              <div>Fecha de cita:</div>
              <div>15/09/2022 - 15:00hs </div>
            </Col>
          </Row>
          <Row>
            <Col className='align-center'>
              <H3>€20.00</H3>
            </Col>
          </Row>
          <Row>
            <Col className='align-center'>
              <Button className='primary' label='Pagar ahora'></Button>
            </Col>
          </Row>
        </Box>
      </Section>
    </div>
  );
}

export default Payment;